import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import TrackingStatus from './TrackingStatus';
import TrackingRecipient from './TrackingRecipient';
import TrackingSender from './TrackingSender';
import TrackingDelivery from './TrackingDelivery';
import TrackingShipping from './TrackingShipping';
import TrackingHistory from './TrackingHistory';

const Tracking = ({ data }) => {
  const { sender, recipient, matrix_name: matrixName, history } = data;

  return (
    <section className="pt-5 pb-5">
      <TrackingStatus {...data} />
      <TrackingHistory history={history} />
      <Container className="mt-4">
        <Row>
          <Col xs="12" md="6" className="mt-4">
            <TrackingSender
              {...sender}
              city={sender.address.city.name}
              state={sender.address.city.state}
            />
          </Col>
          <Col xs="12" md="6" className="mt-4">
            <TrackingRecipient
              {...recipient}
              {...recipient.address}
              city={recipient.address.city.name}
              state={recipient.address.city.state}
            />
          </Col>
        </Row>

        <Row className="mb-5 pb-5">
          <Col xs="12" md="6" className="mt-4">
            <TrackingDelivery {...data} />
          </Col>
          <Col xs="12" md="6" className="mt-4">
            <TrackingShipping name={matrixName} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

Tracking.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string,
    invoice_number: PropTypes.string,
    volumes_weight: PropTypes.number,
    volumes_count: PropTypes.number,
    freight_type: PropTypes.string,
    sender: PropTypes.object,
    recipient: PropTypes.object,
    matrix_name: PropTypes.string,
    estimated_delivery_at: PropTypes.string,
    previous_delivery_expire_at: PropTypes.string,
    history: PropTypes.arrayOf(PropTypes.object),
  }),
};

Tracking.defaultProps = {
  data: {},
};

export default Tracking;
