import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner, Container, Row } from 'reactstrap';
import querystring from 'query-string';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Jumbotron from '../../components/Jumbotron';
import { SECONDARY } from '../../utils/colors';
import Tracking from '../../components/Tracking';
import TrackingForm from '../../components/Tracking/TrackingForm';
import TrackingError from '../../components/Tracking/TrackingError';
import TrackingCard from '../../components/Tracking/TrackingCard';

const getInitialValues = location => {
  if (location.search) {
    const data = querystring.parse(location.search);
    if (data && data.search) {
      return {
        code: data.search.replace('#', ''),
      };
    }
  }
  return null;
};

const TrackingPage = ({ location }) => {
  const formInitialValues = getInitialValues(location);
  const [data, setData] = useState([]);
  const [variables, setVariables] = useState(formInitialValues);
  const [currentTracking, setCurrentTracking] = useState({});
  const [loading, setLoading] = useState(false);

  return (
    <Layout theme="blue" hasTracking={false} location={location}>
      <SEO
        title="Rastreio de Encomendas - Central do Frete"
        description="Rastreio de encomendas ideal, muito mais rápida e descomplicada para qualquer tipo de envio, seja por trasportadora ou correios."
      />
      <Jumbotron
        padding={{ top: '50px', bottom: '50px' }}
        background={SECONDARY}
        title="Rastreio de encomendas"
        subtitle="Preencha o formulário abaixo para ver informações sobre o seu frete."
      >
        <TrackingForm
          initialValues={formInitialValues}
          setData={setData}
          setCurrentTracking={setCurrentTracking}
          setLoading={setLoading}
          variables={variables}
          setVariables={setVariables}
        />
      </Jumbotron>
      {!loading && (
        <>
          {data.length === 0 ? (
            <TrackingError variables={variables} />
          ) : (
            <>
              {data.length > 1 && (
                <Container className="mt-4">
                  <Row>
                    {data.map(track => (
                      <TrackingCard
                        data={track}
                        currentTracking={currentTracking}
                        setCurrentTracking={setCurrentTracking}
                      />
                    ))}
                  </Row>
                </Container>
              )}
              {currentTracking && <Tracking data={currentTracking} />}
            </>
          )}
        </>
      )}
      {loading && (
        <h2 className="text-center pt-5 pb-5">
          <Spinner color="dark" />
        </h2>
      )}
    </Layout>
  );
};

TrackingPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default memo(TrackingPage);
